export let techniquesNist = [
  {
    "techniqueID": "T1110",
    "nist": [
      "PR.AC-4",
      "PR.AC-1",
      "PR.AT-2",
      "PR.AC-3",
      "PR.AC-6"
    ]
  },
  {
    "techniqueID": "T1059",
    "nist": null
  },
  {
    "techniqueID": "T1003",
    "nist": [
      "PR.AC-7",
      "PR.AC-4",
      "PR.AC-1",
      "PR.AT-2"
    ]
  },
  {
    "techniqueID": "T1485",
    "nist": [
      "PR.IP-4"
    ]
  },
  {
    "techniqueID": "T1048",
    "nist": [
      "DE.CM-1",
      "PR.DS-5",
      "PR.AC-5"
    ]
  },
  {
    "techniqueID": "T1041",
    "nist": null
  },
  {
    "techniqueID": "T1567",
    "nist": [
      "PR.PT-4"
    ]
  },
  {
    "techniqueID": "T1011",
    "nist": [
      "PR.PT-4"
    ]
  },
  {
    "techniqueID": "T1052",
    "nist": [
      "DE.CM-1",
      "PR.PT-2",
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1070",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-4"
    ]
  },
  {
    "techniqueID": "T1498",
    "nist": null
  },
  {
    "techniqueID": "T1064",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1064",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1078",
    "nist": [
      "DE.CM-1",
      "PR.AC-7",
      "PR.AT-2",
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1078",
    "nist": [
      "DE.CM-1",
      "PR.AC-7",
      "PR.AT-2",
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1078",
    "nist": [
      "DE.CM-1",
      "PR.AC-7",
      "PR.AT-2",
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1078",
    "nist": [
      "DE.CM-1",
      "PR.AC-7",
      "PR.AT-2",
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1156",
    "nist": null
  },
  {
    "techniqueID": "T1134",
    "nist": null
  },
  {
    "techniqueID": "T1134",
    "nist": null
  },
  {
    "techniqueID": "T1015",
    "nist": null
  },
  {
    "techniqueID": "T1015",
    "nist": null
  },
  {
    "techniqueID": "T1087",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1098",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1098",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1182",
    "nist": null
  },
  {
    "techniqueID": "T1182",
    "nist": null
  },
  {
    "techniqueID": "T1103",
    "nist": null
  },
  {
    "techniqueID": "T1103",
    "nist": null
  },
  {
    "techniqueID": "T1155",
    "nist": null
  },
  {
    "techniqueID": "T1155",
    "nist": null
  },
  {
    "techniqueID": "T1527",
    "nist": null
  },
  {
    "techniqueID": "T1527",
    "nist": null
  },
  {
    "techniqueID": "T1017",
    "nist": null
  },
  {
    "techniqueID": "T1138",
    "nist": [
      "PR.AT-1"
    ]
  },
  {
    "techniqueID": "T1138",
    "nist": [
      "PR.AT-1"
    ]
  },
  {
    "techniqueID": "T1131",
    "nist": null
  },
  {
    "techniqueID": "T1119",
    "nist": null
  },
  {
    "techniqueID": "T1197",
    "nist": null
  },
  {
    "techniqueID": "T1197",
    "nist": null
  },
  {
    "techniqueID": "T1139",
    "nist": null
  },
  {
    "techniqueID": "T1067",
    "nist": [
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1176",
    "nist": null
  },
  {
    "techniqueID": "T1088",
    "nist": null
  },
  {
    "techniqueID": "T1088",
    "nist": null
  },
  {
    "techniqueID": "T1191",
    "nist": null
  },
  {
    "techniqueID": "T1191",
    "nist": null
  },
  {
    "techniqueID": "T1146",
    "nist": null
  },
  {
    "techniqueID": "T1522",
    "nist": null
  },
  {
    "techniqueID": "T1538",
    "nist": null
  },
  {
    "techniqueID": "T1043",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1092",
    "nist": [
      "PR.AC-7"
    ]
  },
  {
    "techniqueID": "T1223",
    "nist": [
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1223",
    "nist": [
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1175",
    "nist": [
      "DE.CM-1",
      "PR.AC-7",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1175",
    "nist": [
      "DE.CM-1",
      "PR.AC-7",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1090",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1090",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1196",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1196",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1136",
    "nist": [
      "PR.AC-7",
      "PR.AC-4",
      "PR.AT-2"
    ]
  },
  {
    "techniqueID": "T1503",
    "nist": null
  },
  {
    "techniqueID": "T1081",
    "nist": null
  },
  {
    "techniqueID": "T1214",
    "nist": [
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1094",
    "nist": [
      "PR.DS-2",
      "DE.CM-4"
    ]
  },
  {
    "techniqueID": "T1024",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1038",
    "nist": null
  },
  {
    "techniqueID": "T1038",
    "nist": null
  },
  {
    "techniqueID": "T1038",
    "nist": null
  },
  {
    "techniqueID": "T1073",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1002",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1132",
    "nist": [
      "DE.CM-1",
      "DE.CM-4"
    ]
  },
  {
    "techniqueID": "T1486",
    "nist": [
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1001",
    "nist": [
      "DE.CM-1",
      "DE.CM-4"
    ]
  },
  {
    "techniqueID": "T1030",
    "nist": [
      "DE.CM-1",
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1530",
    "nist": null
  },
  {
    "techniqueID": "T1213",
    "nist": [
      "PR.AC-4",
      "DE.CM-3"
    ]
  },
  {
    "techniqueID": "T1491",
    "nist": null
  },
  {
    "techniqueID": "T1089",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-4"
    ]
  },
  {
    "techniqueID": "T1488",
    "nist": null
  },
  {
    "techniqueID": "T1487",
    "nist": null
  },
  {
    "techniqueID": "T1172",
    "nist": null
  },
  {
    "techniqueID": "T1483",
    "nist": null
  },
  {
    "techniqueID": "T1482",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1189",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "PR.MA-1"
    ]
  },
  {
    "techniqueID": "T1157",
    "nist": null
  },
  {
    "techniqueID": "T1157",
    "nist": null
  },
  {
    "techniqueID": "T1173",
    "nist": [
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1514",
    "nist": null
  },
  {
    "techniqueID": "T1114",
    "nist": null
  },
  {
    "techniqueID": "T1519",
    "nist": null
  },
  {
    "techniqueID": "T1519",
    "nist": null
  },
  {
    "techniqueID": "T1499",
    "nist": [
      "PR.PT-4"
    ]
  },
  {
    "techniqueID": "T1106",
    "nist": null
  },
  {
    "techniqueID": "T1129",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1190",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "PR.AC-5",
      "DE.CM-8",
      "PR.MA-1"
    ]
  },
  {
    "techniqueID": "T1203",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1",
      "DE.CM-8",
      "PR.MA-1"
    ]
  },
  {
    "techniqueID": "T1212",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1211",
    "nist": null
  },
  {
    "techniqueID": "T1068",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1210",
    "nist": null
  },
  {
    "techniqueID": "T1133",
    "nist": [
      "PR.AC-7",
      "PR.AC-1",
      "PR.AC-3",
      "DE.CM-1",
      "PR.AC-5"
    ]
  },
  {
    "techniqueID": "T1133",
    "nist": [
      "PR.AC-7",
      "PR.AC-1",
      "PR.AC-3",
      "DE.CM-1",
      "PR.AC-5"
    ]
  },
  {
    "techniqueID": "T1008",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1044",
    "nist": null
  },
  {
    "techniqueID": "T1044",
    "nist": null
  },
  {
    "techniqueID": "T1495",
    "nist": null
  },
  {
    "techniqueID": "T1187",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1144",
    "nist": null
  },
  {
    "techniqueID": "T1484",
    "nist": [
      "PR.AC-4"
    ]
  },
  {
    "techniqueID": "T1148",
    "nist": null
  },
  {
    "techniqueID": "T1200",
    "nist": [
      "ID.AM-1",
      "DE.CM-1",
      "PR.AC-7",
      "PR.AC-2"
    ]
  },
  {
    "techniqueID": "T1147",
    "nist": null
  },
  {
    "techniqueID": "T1143",
    "nist": null
  },
  {
    "techniqueID": "T1525",
    "nist": null
  },
  {
    "techniqueID": "T1054",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-4"
    ]
  },
  {
    "techniqueID": "T1490",
    "nist": null
  },
  {
    "techniqueID": "T1130",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-4"
    ]
  },
  {
    "techniqueID": "T1118",
    "nist": null
  },
  {
    "techniqueID": "T1118",
    "nist": null
  },
  {
    "techniqueID": "T1208",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1215",
    "nist": null
  },
  {
    "techniqueID": "T1142",
    "nist": null
  },
  {
    "techniqueID": "T1161",
    "nist": null
  },
  {
    "techniqueID": "T1149",
    "nist": null
  },
  {
    "techniqueID": "T1171",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1177",
    "nist": [
      "DE.CM-1",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1177",
    "nist": [
      "DE.CM-1",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1159",
    "nist": null
  },
  {
    "techniqueID": "T1160",
    "nist": null
  },
  {
    "techniqueID": "T1160",
    "nist": null
  },
  {
    "techniqueID": "T1152",
    "nist": null
  },
  {
    "techniqueID": "T1152",
    "nist": null
  },
  {
    "techniqueID": "T1152",
    "nist": null
  },
  {
    "techniqueID": "T1168",
    "nist": null
  },
  {
    "techniqueID": "T1168",
    "nist": null
  },
  {
    "techniqueID": "T1162",
    "nist": null
  },
  {
    "techniqueID": "T1037",
    "nist": null
  },
  {
    "techniqueID": "T1037",
    "nist": null
  },
  {
    "techniqueID": "T1185",
    "nist": null
  },
  {
    "techniqueID": "T1036",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1031",
    "nist": [
      "PR.AC-7",
      "PR.AC-4",
      "PR.AC-1",
      "PR.AT-2"
    ]
  },
  {
    "techniqueID": "T1112",
    "nist": [
      "DE.CM-1",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1170",
    "nist": null
  },
  {
    "techniqueID": "T1170",
    "nist": null
  },
  {
    "techniqueID": "T1104",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1188",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1026",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1079",
    "nist": null
  },
  {
    "techniqueID": "T1096",
    "nist": [
      "RS.AN-3"
    ]
  },
  {
    "techniqueID": "T1046",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1040",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1040",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1050",
    "nist": null
  },
  {
    "techniqueID": "T1050",
    "nist": null
  },
  {
    "techniqueID": "T1027",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1137",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1075",
    "nist": null
  },
  {
    "techniqueID": "T1097",
    "nist": null
  },
  {
    "techniqueID": "T1174",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1201",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1034",
    "nist": [
      "PR.AC-4",
      "PR.AC-1",
      "PR.AT-2"
    ]
  },
  {
    "techniqueID": "T1034",
    "nist": [
      "PR.AC-4",
      "PR.AC-1",
      "PR.AT-2"
    ]
  },
  {
    "techniqueID": "T1150",
    "nist": null
  },
  {
    "techniqueID": "T1150",
    "nist": null
  },
  {
    "techniqueID": "T1150",
    "nist": null
  },
  {
    "techniqueID": "T1205",
    "nist": null
  },
  {
    "techniqueID": "T1205",
    "nist": null
  },
  {
    "techniqueID": "T1205",
    "nist": null
  },
  {
    "techniqueID": "T1086",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1504",
    "nist": null
  },
  {
    "techniqueID": "T1504",
    "nist": null
  },
  {
    "techniqueID": "T1145",
    "nist": [
      "PR.AC-7",
      "PR.AC-4",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1055",
    "nist": null
  },
  {
    "techniqueID": "T1055",
    "nist": null
  },
  {
    "techniqueID": "T1163",
    "nist": null
  },
  {
    "techniqueID": "T1164",
    "nist": null
  },
  {
    "techniqueID": "T1108",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1108",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1121",
    "nist": null
  },
  {
    "techniqueID": "T1121",
    "nist": null
  },
  {
    "techniqueID": "T1117",
    "nist": null
  },
  {
    "techniqueID": "T1117",
    "nist": null
  },
  {
    "techniqueID": "T1219",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1076",
    "nist": null
  },
  {
    "techniqueID": "T1105",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1105",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1021",
    "nist": null
  },
  {
    "techniqueID": "T1091",
    "nist": [
      "DE.CM-1",
      "PR.PT-2",
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1091",
    "nist": [
      "DE.CM-1",
      "PR.PT-2",
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1085",
    "nist": null
  },
  {
    "techniqueID": "T1085",
    "nist": null
  },
  {
    "techniqueID": "T1494",
    "nist": null
  },
  {
    "techniqueID": "T1178",
    "nist": null
  },
  {
    "techniqueID": "T1198",
    "nist": [
      "ID.SC-1",
      "ID.SC-2",
      "ID.SC-4",
      "ID.SC-3"
    ]
  },
  {
    "techniqueID": "T1198",
    "nist": [
      "ID.SC-1",
      "ID.SC-2",
      "ID.SC-4",
      "ID.SC-3"
    ]
  },
  {
    "techniqueID": "T1184",
    "nist": null
  },
  {
    "techniqueID": "T1053",
    "nist": null
  },
  {
    "techniqueID": "T1053",
    "nist": null
  },
  {
    "techniqueID": "T1053",
    "nist": null
  },
  {
    "techniqueID": "T1029",
    "nist": [
      "DE.CM-1",
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1113",
    "nist": null
  },
  {
    "techniqueID": "T1180",
    "nist": null
  },
  {
    "techniqueID": "T1101",
    "nist": null
  },
  {
    "techniqueID": "T1505",
    "nist": [
      "PR.AC-4",
      "PR.AC-1",
      "PR.AT-2"
    ]
  },
  {
    "techniqueID": "T1035",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-7",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1058",
    "nist": null
  },
  {
    "techniqueID": "T1058",
    "nist": null
  },
  {
    "techniqueID": "T1489",
    "nist": null
  },
  {
    "techniqueID": "T1166",
    "nist": null
  },
  {
    "techniqueID": "T1166",
    "nist": null
  },
  {
    "techniqueID": "T1051",
    "nist": null
  },
  {
    "techniqueID": "T1023",
    "nist": null
  },
  {
    "techniqueID": "T1218",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1218",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1216",
    "nist": null
  },
  {
    "techniqueID": "T1216",
    "nist": null
  },
  {
    "techniqueID": "T1045",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1193",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "PR.AT-1",
      "PR.PT-3"
    ]
  },
  {
    "techniqueID": "T1192",
    "nist": [
      "DE.CM-1",
      "PR.PT-3",
      "PR.AT-1"
    ]
  },
  {
    "techniqueID": "T1194",
    "nist": [
      "DE.CM-1",
      "PR.PT-3",
      "PR.AT-1",
      "DE.CM-4"
    ]
  },
  {
    "techniqueID": "T1071",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1032",
    "nist": null
  },
  {
    "techniqueID": "T1095",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1165",
    "nist": null
  },
  {
    "techniqueID": "T1165",
    "nist": null
  },
  {
    "techniqueID": "T1528",
    "nist": null
  },
  {
    "techniqueID": "T1539",
    "nist": null
  },
  {
    "techniqueID": "T1492",
    "nist": null
  },
  {
    "techniqueID": "T1169",
    "nist": null
  },
  {
    "techniqueID": "T1206",
    "nist": null
  },
  {
    "techniqueID": "T1195",
    "nist": [
      "PR.DS-6",
      "DE.CM-8",
      "PR.MA-1"
    ]
  },
  {
    "techniqueID": "T1565",
    "nist": [
      "PR.AC-6",
      "PR.AC-5",
      "PR.DS-5"
    ]
  },
  {
    "techniqueID": "T1019",
    "nist": [
      "PR.IP-1"
    ]
  },
  {
    "techniqueID": "T1501",
    "nist": null
  },
  {
    "techniqueID": "T1080",
    "nist": null
  },
  {
    "techniqueID": "T1221",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1072",
    "nist": [
      "ID.SC-3",
      "ID.SC-2"
    ]
  },
  {
    "techniqueID": "T1072",
    "nist": [
      "ID.SC-3",
      "ID.SC-2"
    ]
  },
  {
    "techniqueID": "T1209",
    "nist": null
  },
  {
    "techniqueID": "T1537",
    "nist": [
      "PR.DS-2"
    ]
  },
  {
    "techniqueID": "T1493",
    "nist": null
  },
  {
    "techniqueID": "T1127",
    "nist": null
  },
  {
    "techniqueID": "T1127",
    "nist": null
  },
  {
    "techniqueID": "T1199",
    "nist": [
      "DE.CM-1",
      "PR.AC-5",
      "PR.AC-7"
    ]
  },
  {
    "techniqueID": "T1065",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1535",
    "nist": null
  },
  {
    "techniqueID": "T1204",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1102",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1102",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1506",
    "nist": null
  },
  {
    "techniqueID": "T1506",
    "nist": null
  },
  {
    "techniqueID": "T1100",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1100",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1077",
    "nist": null
  },
  {
    "techniqueID": "T1047",
    "nist": null
  },
  {
    "techniqueID": "T1084",
    "nist": null
  },
  {
    "techniqueID": "T1028",
    "nist": null
  },
  {
    "techniqueID": "T1028",
    "nist": null
  },
  {
    "techniqueID": "T1004",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1",
      "PR.AC-7",
      "PR.AC-4",
      "PR.AC-1"
    ]
  },
  {
    "techniqueID": "T1220",
    "nist": [
      "DE.CM-1",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1220",
    "nist": [
      "DE.CM-1",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1141",
    "nist": [
      "PR.AT-1"
    ]
  },
  {
    "techniqueID": "T1111",
    "nist": null
  },
  {
    "techniqueID": "T1531",
    "nist": null
  },
  {
    "techniqueID": "T1010",
    "nist": null
  },
  {
    "techniqueID": "T1123",
    "nist": null
  },
  {
    "techniqueID": "T1020",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1009",
    "nist": null
  },
  {
    "techniqueID": "T1217",
    "nist": null
  },
  {
    "techniqueID": "T1042",
    "nist": null
  },
  {
    "techniqueID": "T1115",
    "nist": null
  },
  {
    "techniqueID": "T1526",
    "nist": [
      "ID.AM-2"
    ]
  },
  {
    "techniqueID": "T1116",
    "nist": null
  },
  {
    "techniqueID": "T1500",
    "nist": null
  },
  {
    "techniqueID": "T1109",
    "nist": null
  },
  {
    "techniqueID": "T1109",
    "nist": null
  },
  {
    "techniqueID": "T1122",
    "nist": null
  },
  {
    "techniqueID": "T1122",
    "nist": null
  },
  {
    "techniqueID": "T1207",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1022",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1074",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1005",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1039",
    "nist": [
      "DE.CM-1",
      "DE.CM-7"
    ]
  },
  {
    "techniqueID": "T1025",
    "nist": [
      "PR.PT-2"
    ]
  },
  {
    "techniqueID": "T1140",
    "nist": null
  },
  {
    "techniqueID": "T1480",
    "nist": null
  },
  {
    "techniqueID": "T1181",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1181",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1107",
    "nist": null
  },
  {
    "techniqueID": "T1006",
    "nist": null
  },
  {
    "techniqueID": "T1083",
    "nist": [
      "PR.AC-4",
      "DE.CM-3"
    ]
  },
  {
    "techniqueID": "T1222",
    "nist": null
  },
  {
    "techniqueID": "T1061",
    "nist": null
  },
  {
    "techniqueID": "T1158",
    "nist": null
  },
  {
    "techniqueID": "T1158",
    "nist": null
  },
  {
    "techniqueID": "T1179",
    "nist": null
  },
  {
    "techniqueID": "T1179",
    "nist": null
  },
  {
    "techniqueID": "T1179",
    "nist": null
  },
  {
    "techniqueID": "T1062",
    "nist": null
  },
  {
    "techniqueID": "T1183",
    "nist": null
  },
  {
    "techniqueID": "T1183",
    "nist": null
  },
  {
    "techniqueID": "T1183",
    "nist": null
  },
  {
    "techniqueID": "T1066",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1202",
    "nist": null
  },
  {
    "techniqueID": "T1056",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1056",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1534",
    "nist": null
  },
  {
    "techniqueID": "T1128",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1126",
    "nist": [
      "RS.AN-3"
    ]
  },
  {
    "techniqueID": "T1135",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1502",
    "nist": null
  },
  {
    "techniqueID": "T1502",
    "nist": null
  },
  {
    "techniqueID": "T1120",
    "nist": null
  },
  {
    "techniqueID": "T1069",
    "nist": null
  },
  {
    "techniqueID": "T1013",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1013",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1057",
    "nist": null
  },
  {
    "techniqueID": "T1186",
    "nist": null
  },
  {
    "techniqueID": "T1093",
    "nist": null
  },
  {
    "techniqueID": "T1012",
    "nist": null
  },
  {
    "techniqueID": "T1060",
    "nist": null
  },
  {
    "techniqueID": "T1018",
    "nist": [
      "DE.CM-1",
      "DE.CM-7",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1496",
    "nist": null
  },
  {
    "techniqueID": "T1536",
    "nist": null
  },
  {
    "techniqueID": "T1014",
    "nist": [
      "DE.CM-1",
      "DE.CM-4",
      "RS.AN-1"
    ]
  },
  {
    "techniqueID": "T1063",
    "nist": null
  },
  {
    "techniqueID": "T1167",
    "nist": null
  },
  {
    "techniqueID": "T1518",
    "nist": [
      "ID.AM-2"
    ]
  },
  {
    "techniqueID": "T1153",
    "nist": null
  },
  {
    "techniqueID": "T1151",
    "nist": null
  },
  {
    "techniqueID": "T1151",
    "nist": null
  },
  {
    "techniqueID": "T1082",
    "nist": null
  },
  {
    "techniqueID": "T1016",
    "nist": null
  },
  {
    "techniqueID": "T1049",
    "nist": null
  },
  {
    "techniqueID": "T1033",
    "nist": null
  },
  {
    "techniqueID": "T1007",
    "nist": null
  },
  {
    "techniqueID": "T1529",
    "nist": null
  },
  {
    "techniqueID": "T1124",
    "nist": null
  },
  {
    "techniqueID": "T1099",
    "nist": [
      "RS.AN-3"
    ]
  },
  {
    "techniqueID": "T1154",
    "nist": null
  },
  {
    "techniqueID": "T1543",
    "nist": ["PR.PT-1"]
  },
  {
    "techniqueID": "T1154",
    "nist": null
  },
  {
    "techniqueID": "T1125",
    "nist": [
      "DE.CM-1"
    ]
  },
  {
    "techniqueID": "T1497",
    "nist": null
  },
  {
    "techniqueID": "T1497",
    "nist": null
  }
]