'use strict';

 //   ___ _    ___  ___   _   _     __   ___   ___ ___   _   ___ _    ___ ___
 //  / __| |  / _ \| _ ) /_\ | |    \ \ / /_\ | _ \_ _| /_\ | _ ) |  | __/ __|
 // | (_ | |_| (_) | _ \/ _ \| |__   \ V / _ \|   /| | / _ \| _ \ |__| _|\__ \
 //  \___|____\___/|___/_/ \_\____|   \_/_/ \_\_|_\___/_/ \_\___/____|___|___/
 //

export const nav_version: string = "4.7.1";
export const layer_version: string = "4.3";
